<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ContactEndpoint from '~~/app/contact/endpoints/ContactEndpoint';
    import Contact from '~~/app/contact/models/Contact';

    const endpoint = EndpointFactory.make(ModelType.CONTACTS) as ContactEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: Contact) => {
        return await endpoint.destroy(model.getId());
    };
    const openWarningBeforeDestroyModal = (model: Contact) => {
        useEvent('contact:contact:context-menu:close');
        useEvent('contact:contact:destroy-alert:open', { callback: () => destroy(model), model });
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="Contact"
        :menu-height="200"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            ref="deleteButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="openWarningBeforeDestroyModal(slotProps.model)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
