import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ContactRelationships = {
    defaultVatRate: ToOneRelationship<ModelType.VAT_RATES>;
};

type ContactAttributes = {
    name: string;
    email: string;
    phone: string;
    vatNumber: string;
    locale: string;
    contactType: string;
    honorificPrefix: string;
    comments: string;
    options: Record<string, any>;
    address: Address | null;
};

type ContactMeta = {};

export default class ContactSchema extends BaseSchema<ContactAttributes, ContactMeta, ContactRelationships> {
    protected type: ModelType = ModelType.CONTACTS;
}
