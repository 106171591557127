<template>
    <div>
        <Modal
            ref="modal"
            name="worksite:worksite-progress-billing:validation-modal"
        >
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoicing.invoice.send_modal.success.title')"
                    :subtitle="
                        $t('invoicing.invoice.send_modal.success.text', {
                            to: form.get('sendTo'),
                        })
                    "
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.white_border')"
                            @clicked="goToBillingProgress()"
                        >
                            {{ $t('actions.open_worksite_progress_billing') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('worksite:worksite-progress-billing:validation-modal:close')"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>

            <div v-if="!success">
                <div :class="$theme('modal.title.container')">
                    <h2
                        :class="$theme('modal.title.text')"
                        class="first-letter:capitalize"
                    >
                        {{ form.fields.title }}
                    </h2>
                </div>
                <form
                    class="mb-4"
                    @submit.prevent="submit"
                >
                    <div
                        :class="$theme('modal.padding')"
                        class="space-y-4"
                    >
                        <div class="w-full md:flex-auto">
                            <div class="flex font-thin text-gray-500">
                                <div class="flex items-center">
                                    <div class="pr-2">
                                        <input
                                            id="validation"
                                            v-model="shouldValidateBy"
                                            type="checkbox"
                                        />
                                        <label for="validation"></label>
                                    </div>
                                    <label
                                        for="validation"
                                        class="text-sm font-normal text-gray-500 cursor-pointer"
                                    >
                                        {{ $t('worksite_progress_billing.modal.validation.label.validateBy') }}
                                        <!----></label
                                    >
                                </div>
                            </div>
                            <!---->
                            <!---->
                        </div>
                        <div v-if="shouldValidateBy">
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="wpbrvfm_email"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.recipient') }}
                                    <!----></label
                                >
                                <div class="w-full md:flex-auto">
                                    <FormEmailBadge
                                        id="wpbrvfm_email"
                                        :value="form.get('sendTo')"
                                        :placeholder="$t('form.labels.email')"
                                        @changed="(value) => form.set('to', value)"
                                    />
                                </div>
                            </div>
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="invoice-send-form-subject"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.email_subject') }}
                                </label>
                                <div class="flex-1">
                                    <input
                                        id="invoice-send-form-subject"
                                        name="subject"
                                        :value="form.get('subject')"
                                        class="w-full p-2 text-sm text-gray-600 outline-none focus:text-black"
                                        @change="form.set('subject', $event.target.value)"
                                    />
                                </div>
                            </div>
                            <div class="py-4">
                                <FormEditor
                                    :form="form"
                                    element-class-name="w-full"
                                    input-name="text"
                                    without-label
                                    class-name="w-full text-gray-600 text-sm font-thin focus:text-black outline-none h-[100px]"
                                />
                            </div>
                        </div>

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('worksite:worksite-progress-billing:validation-modal:close')"
                                >{{ $t('actions.cancel') }}</LoadingButton
                            >
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :loading="form.isLoading"
                                type="submit"
                            >
                                <i class="mr-2 fa-solid fa-paper-plane"></i>
                                {{ $t('actions.validate') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { UnwrapNestedRefs } from 'vue';
    import WorksiteProgressBillingForm from '../../../app/worksite/classes/WorksiteProgressBillingForm';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import { ModelType } from '../../../app/base/schemas/BaseSchema';
    import WorksiteProgressBillingEndpoint from '../../../app/worksite/endpoints/WorskiteProgressBillingEndpoint';
    import WorksiteProgressBilling from '../../../app/worksite/models/WorksiteProgressBilling';
    import ProgressBillingRoute from '../../../classes/ProgressBillingRoute';

    const shouldValidateBy = ref<boolean>(true);
    const worksiteProgressBilling = ref<WorksiteProgressBilling | null>(null);
    export interface progressBillingValidationForm {
        sendTo: string;
        subject: string;
        title: string;
        text: string;
    }
    const form = useForm<{
        sendTo: string;
        subject: string;
        title: string;
        text: string;
    }>({
        sendTo: '',
        subject: '',
        title: '',
        text: '',
    });

    const { authed } = useAuthStore();
    const { t } = useTranslation();
    useModal('worksite:worksite-progress-billing:validation-modal', (payload: UnwrapNestedRefs<WorksiteProgressBilling>) => {
        worksiteProgressBilling.value = payload.value;
        console.log(worksiteProgressBilling.value?.worksite?.getId());
        form.set('sendTo', authed?.email);
        form.set(
            'subject',
            t('worksite_progress_billing.modal.validation.fields.subject', {
                progress_billing: worksiteProgressBilling.value?.number,
            })
        );
        form.set(
            'title',
            t('worksite_progress_billing.modal.validation.fields.title', {
                progress_billing: worksiteProgressBilling.value?.number,
            })
        );
        form.set(
            'text',
            t('worksite_progress_billing.modal.validation.fields.text', {
                progress_billing: worksiteProgressBilling.value?.number,
            })
        );
    });

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROGRESS_BILLINGS) as WorksiteProgressBillingEndpoint;
    const success = ref(false);
    const goToBillingProgress = () => {
        useEvent('worksite:worksite-progress-billing:validation-modal:close');
        worksiteProgressBilling.value?.edit(worksiteProgressBilling.value?.worksite);
    };

    const submit = async () => {
        const response = ref();
        if (shouldValidateBy.value) {
            response.value = await form.loadUntil(endpoint.requestValidation(worksiteProgressBilling.value?.getId() as string, form.fields));
        }
        if (!shouldValidateBy.value) {
            response.value = await form.loadUntil(endpoint.validated(worksiteProgressBilling.value?.getId() as string));
        }

        if (response.value.error) return useToasteoError();
        useEvent('worksite:worksite-progress-billing:updated', response.value.data);
        success.value = true;
        useToasteoSuccess();
    };
</script>
